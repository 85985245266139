import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import { Theme } from '@material-ui/core/styles';
import { InputAdornment } from '@mui/material';
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '@/redux/store'
import { addCrosssellOrder, updateCrosssellOrder } from '../../src/redux/features//BookingSlice';
interface StyleProps {
  theme: Theme;
}

interface Service {
  id: number | string;
  name: string;
  base_price: string;
  quantity: number;
  checked: boolean;
  new:boolean | null
}

interface AdditionalService {
  name: string;
  base_price: string;
  quantity: number;
  new:boolean | null,
  id: number | null
}

type orderedCrosssell = {
  [key: string]: any[]
}

interface CrossSellModalProps {
  open: boolean;
  onClose: () => void;
  crossellList: Service[];
  fetchBookingDetail: () => void;
  orderedCrosssell: orderedCrosssell  ;
  orderId: number

}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      minWidth: 600,
    },
  },
  dialogTitle: {
    padding: theme.spacing(3),
    '& h2': {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
  subtitle: {
    color: '#666',
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3, 3),
  },
  serviceRow: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  additionalServiceRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    color: '#666',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    minWidth: 'auto',
    padding: theme.spacing(0.5),
    color: '#EF4444',
    '&:hover': {
      backgroundColor: 'rgba(239, 68, 68, 0.04)',
    },
  },
  quantityControl: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    '& input': {
      width: 40,
      textAlign: 'center',
      border: 'none',
      padding: theme.spacing(0.5),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  iconButton: {
    padding: 4,
    color: '#6366F1',
    '&.Mui-disabled': {
      color: '#E0E0E0',
    },
  },
  priceField: {
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F9FAFB',
    },
  },
  totalPrice: {
    color: '#333',
    fontWeight: 500,
  },
  checkbox: {
    color: '#6366F1',
    '&.Mui-checked': {
      color: '#6366F1',
    },
  },
  serviceAction: {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid #E0E0E0',
    marginLeft: -25,
    marginRight: -25,
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
  cancelButton: {
    textTransform: 'none',
    color: '#666',
    marginRight: theme.spacing(1),
  },
  saveButton: {
    textTransform: 'none',
    backgroundColor: '#6366F1',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4F46E5',
    },
  },
  serviceNameField: {
    '& .MuiOutlinedInput-root': {
      height: 40,
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
  },
}));

const CrossSellModal: React.FC<CrossSellModalProps> = ({ open, onClose, crossellList, fetchBookingDetail, orderedCrosssell, orderId }) => {
  const classes = useStyles();
  const [services, setServices] = useState<Service[]>([]);
  const [additionalServices, setAdditionalServices] = useState<AdditionalService[]>([]);
  const [disabledServices, setDisabledServices] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>()
  
  useEffect(() => {
    if (crossellList.length) {
      // Map crossellList to create updated objects
      const updatedObjects = crossellList.map((obj) => {
        // Check if the object exists in orderedCrosssell based on the name
        const matchedItem = orderedCrosssell?.inventory_items?.find(
          (ordered) => ordered.item_inventory_id == obj.id
        );
        console.log('matchedItem', matchedItem);
        // If there's a match, update the quantity and checked status
        if (matchedItem) {
          return {
            ...obj,
            quantity: matchedItem.quantity,
            checked: true,
            base_price: matchedItem.amount,
            new:null
          };
        }
  
        // Otherwise, keep default values
        return {
          ...obj,
          quantity: 1,
          checked: false,
          new:true
        };
      });
     console.log('updatedObjects', updatedObjects);
      // Update the state with the modified list
      setServices(updatedObjects);
    }
  }, [crossellList, orderedCrosssell]);

  useEffect(()=>{

    if(orderedCrosssell){
      
      const filteredOtherService = orderedCrosssell?.other_items?.map((service) => {
        return {
          name: service.name,
          base_price: service.amount,
          quantity: service.quantity,
          new:null,
          id:service.id
        }
      })
      console.log('orderforOtherServices', filteredOtherService);
      if(filteredOtherService){
        setAdditionalServices(filteredOtherService)
      }
     
    }

  },[orderedCrosssell])
  


  useEffect(()=>{

      let filteredarr = services.filter((service) => service.checked)
      if(!additionalServices.length){
        if(filteredarr.length){
          setDisabled(false)
        } else{
          setDisabled(true)
        }
      } else{
       setDisabled(disabledServices)
      }
   
    

  },[services, disabledServices, additionalServices])


  const handleAddService = (): void => {
    const newService: AdditionalService = {
      name: '',
      base_price: '0',
      quantity: 1,
      new:true,
      id:null

    };
    setAdditionalServices([...additionalServices, newService]);
  };

  const handleServiceQuantityChange = (id: number | string, increment: number): void => {
    const updatedServices = services.map((service) =>
      service.id === id
        ? { ...service, quantity: Math.max(1, service.quantity + increment) }
        : service
    );
    setServices(updatedServices);
  };

  useEffect(() => {
    if (additionalServices.length > 0) {
      setDisabledServices(
        additionalServices.some((service) => service.name === '' || service.base_price === '0')
      );
    } else {
      setDisabledServices(false);
    }
  }, [additionalServices]);

  console.log('additionalServices', additionalServices);
  console.log('services', services);
  const handleDeleteService = (index: number): void => {
    const updatedServices = additionalServices.filter((_, i) => i !== index);
    setAdditionalServices(updatedServices);
  };

  const handleAdditionalServiceQuantityChange = (index: number, increment: number): void => {
    const updatedServices = additionalServices.map((service, i) =>
      i === index
        ? { ...service, quantity: Math.max(1, service.quantity + increment) }
        : service
    );
    setAdditionalServices(updatedServices);
  };

  const handleAdditionalServiceChange = (
    index: number,
    field: keyof Service,
    value: string | number
  ): void => {
    const updatedServices = additionalServices.map((service, i) =>
      i === index ? { ...service, [field]: value } : service
    );
    setAdditionalServices(updatedServices);
  };

  const handleServiceChange = (index: number, field: keyof Service, value: string | number): void => {
    const updatedServices = services.map((service, i) =>
      i === index ? { ...service, [field]: value } : service
    );
    setServices(updatedServices);
  };
  
const createCrosssellOrder=()=>{
  let bookingId = (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] || '';
  let cross_sell_items = services.filter((service) => service.checked).map((service) => ({
    id: service.id,
    name: service.name,
    quantity: service.quantity,
    price: parseFloat(parseFloat(service.base_price).toFixed(2))
  }))
  
  let additional_cross_sell_items = additionalServices.filter((service) => service.name && service.base_price!=='0').map((obj)=>{
      return {
        name: obj.name,
        quantity: obj.quantity,
        price: parseFloat(parseFloat(obj.base_price).toFixed(2))
      }
  })

 let final_cross_sell = [...cross_sell_items,...additional_cross_sell_items]
  
  console.log('final_cross_sell',final_cross_sell)
  let data = {
    booking_id:parseFloat(bookingId),
    cross_sell_items:final_cross_sell
  }

  dispatch(addCrosssellOrder(data)).then((res)=>{
    if(res.type === 'addCrosssellOrder/fulfilled'){
      console.log(res)
      fetchBookingDetail()
      onClose()
    }
  })

}

const updateOrder=()=>{
  console.log('service before update',services)
  const newservices = services.filter((service) => service.checked && service.new).map((obj)=>{
    return {
      name: obj.name,
      quantity: obj.quantity,
      price: parseFloat(parseFloat(obj.base_price).toFixed(2)),
      item_inventory_id:obj.id
    }
  })

  console.log('newservices',newservices)
  const newotherservices = additionalServices.filter((service) => service.new).map((service)=>{
    return {
      name: service.name,
      quantity: service.quantity,
      price: parseFloat(parseFloat(service.base_price).toFixed(2)),
      item_inventory_id:null
    }
  })

  let updatePayloadArr = [...newservices,...newotherservices]
 
 

  if(orderedCrosssell){
    let existingService = orderedCrosssell?.inventory_items
    ?.filter((obj) => {
      const itemobj = services.find((item) => item.id === obj.item_inventory_id);
      return itemobj?.checked;
    })
    ?.map((obj) => {
      const itemobj = services.find((item) => item.id === obj.item_inventory_id);
      return {
        name: itemobj?.name || obj.name, // Prefer name from itemobj if available
        quantity: itemobj?.quantity || obj.quantity, // Prefer quantity from itemobj if available
        price: parseFloat(parseFloat(itemobj?.base_price || obj.amount).toFixed(2)), // Prefer price from itemobj if available
        item_inventory_id: obj.item_inventory_id,
        id: obj.id,
      };
    });
  

    let existingOtherService = orderedCrosssell?.other_items
    ?.filter((obj) => additionalServices.some((item) => item.id === obj.id))
    ?.map((obj) => {
      const matchedItem = additionalServices.find((item) => item.id === obj.id); // Find the matching item
      return {
        name: matchedItem?.name || obj.name, // Use matched item's name if available
        quantity: matchedItem?.quantity || obj.quantity, // Use matched item's quantity if available
        price: parseFloat(
          parseFloat(matchedItem?.base_price || obj.amount).toFixed(2)
        ), // Use matched item's price if available
        id: obj.id,
        item_inventory_id: obj.item_inventory_id,
      };
    });
  

    updatePayloadArr = [...updatePayloadArr,...existingService,...existingOtherService]
    console.log('updatePayloadArr',updatePayloadArr)
  }

  dispatch(updateCrosssellOrder({ order: updatePayloadArr, orderId })).then((res)=>{
    if(res.type === 'updateCrosssellOrder/fulfilled'){
      console.log(res)
      fetchBookingDetail()
      onClose()
    }
  })

}

  const handleCheckboxChange = (id: number | string): void => {
    const updateChecked = (list: Service[]) =>
      list.map((service) =>
        service.id === id ? { ...service, checked: !service.checked } : service
      );

      setServices(updateChecked(services));
    
  };

  const calculateTotalPrice = (base_price: string, quantity: number): string => {
    const total = parseFloat(base_price) * quantity;
  
    // Check for NaN and return "0.00" if the result is invalid
    if (isNaN(total)) {
      return "0.00";
    }
  
    return total.toFixed(2);
  };
  

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth='md'
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant='h2'>Select Cross-sell Services</Typography>
        <Typography className={classes.subtitle}>
          Extra service charges will apply for additional guests.
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.headerRow}>
          <div></div>
          <div>SERVICE</div>
          <div>QUANTITY</div>
          <div>BASE PRICE</div>
          <div>TOTAL PRICE</div>
          <div>ACTION</div>
        </div>

        {services.map((service, index) => (
          <div key={service.id} className={classes.serviceRow}>
            <Checkbox
              checked={service.checked}
              onChange={() => handleCheckboxChange(service.id)}
              className={classes.checkbox}
            />
            <Typography>{service.name}</Typography>
            <div className={classes.quantityControl}>
              <IconButton
                className={classes.iconButton}
                size='small'
                onClick={() => handleServiceQuantityChange(service.id, -1)}
                disabled={service.quantity <= 1}
              >
                <RemoveIcon fontSize='small' />
              </IconButton>
              <input type='text' value={service.quantity} readOnly />
              <IconButton
                className={classes.iconButton}
                size='small'
                onClick={() => handleServiceQuantityChange(service.id, 1)}
              >
                <AddIcon fontSize='small' />
              </IconButton>
            </div>
            <TextField
              variant='outlined'
              size='small'
              value={service.base_price}
              className={classes.priceField}
              onChange={(e) => {
                console.log(e.target.value);
                // Remove any character that is not a digit or a decimal point
                let value = e.target.value.replace(/[^0-9.]/g, '');
                value = value.replace(/^0+/, '') || '0';
                value = value.replace(/(\..*?)\./g, '$1');
                console.log(value);
                // If the cleaned value is empty, default to '0'
                handleServiceChange(index, 'base_price', value || '0');
              }}
              
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>₹</InputAdornment>
                ),
              }}
            />
            
            <Typography className={classes.totalPrice}>
              ₹ {calculateTotalPrice(service.base_price, service.quantity)}
            </Typography>
          </div>
        ))}

        <div className={classes.serviceAction}>
          {additionalServices.map((service, index) => (
            <div
              key={`additional-service-${index}`}
              className={classes.additionalServiceRow}
              style={{ marginTop: 10 }}
            >
              <TextField
                placeholder='Enter service'
                variant='outlined'
                fullWidth
                value={service.name}
                onChange={(e) =>
                  handleAdditionalServiceChange(index, 'name', e.target.value)
                }
                className={classes.serviceNameField}
              />
              <div className={classes.quantityControl}>
                <IconButton
                  className={classes.iconButton}
                  size='small'
                  onClick={() =>
                    handleAdditionalServiceQuantityChange(index, -1)
                  }
                  disabled={service.quantity <= 1}
                >
                  <RemoveIcon fontSize='small' />
                </IconButton>
                <input type='text' value={service.quantity} readOnly />
                <IconButton
                  className={classes.iconButton}
                  size='small'
                  onClick={() =>
                    handleAdditionalServiceQuantityChange(index, 1)
                  }
                >
                  <AddIcon fontSize='small' />
                </IconButton>
              </div>
              <TextField
                variant='outlined'
                size='small'
                value={service.base_price}
                onChange={(e) => {
                  console.log(e.target.value);
                  // Remove any character that is not a digit or a decimal point
                  let value = e.target.value.replace(/[^0-9.]/g, '');
                  value = value.replace(/^0+/, '') || '0';
                  value = value.replace(/(\..*?)\./g, '$1');
                  console.log(value);
                  // If the cleaned value is empty, default to '0'
                  handleAdditionalServiceChange(index, 'base_price', value || '0');
                }}
                className={classes.priceField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>₹</InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.totalPrice}>
                ₹ {calculateTotalPrice(service.base_price, service.quantity)}
              </Typography>
              <IconButton
                className={classes.deleteButton}
                onClick={() => handleDeleteService(index)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            variant='contained'
            className={classes.saveButton}
            disabled={disabledServices}
            onClick={handleAddService}
          >
            Add Service
          </Button>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.cancelButton}>
          Cancel
        </Button>

        {
          !orderId?  <Button variant='contained' className={classes.saveButton}
          onClick={createCrosssellOrder}
          disabled={isDisabled}
         >
          Save
           
         </Button>:
          <Button variant='contained' className={classes.saveButton}
          onClick={updateOrder}
          disabled={disabledServices}
         >
          Update
           
         </Button>
        }
       
      </DialogActions>
    </Dialog>
  )
};

export default CrossSellModal;