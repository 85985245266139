import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '@/redux/store'
import {
  addOffer,
  closeModal,
  createInvoices,
  getBookingId,
  getOffersList,
  openModal,
  removeOffer,
  getCrosssellOrder,
} from '../../redux/features/BookingSlice'
import {Box, Button, Container, Grid, Link, Paper, Typography,} from '@mui/material'
import {makeStyles} from '@material-ui/core/styles'
import {createTheme} from '@mui/material/styles'
import BreadcrumbsComponent from '../../components/Breadcrums/Breadcrums'
import moment from 'moment'
import ModalComponent from '../../components/Modal'
import Input from '../../components/Input'
import {ToastContainer} from 'react-toastify'
import EditIcon from '@mui/icons-material/Edit'
import generateConfig, {Config} from '../../components/HelmetConfig'
import {Helmet} from 'react-helmet'
import Dropdown from '../../components/Dropdown'
import CrossSellModal from '../../components/CrossSellModal'
import { getCrosssellList } from '../../redux/features/BookingSlice'

interface Address {
  city: string
  area: string
  pincode: string
  // Add more booking as needed
}

interface Booking {
  name: string
  Address: Address
  code: string
  onboarding_stage_id: number
  primary_owner_id: number
  key_account_manager_id: number
  KeyAccountManager: KeyAccountManager
  Channel: Channel[]
  
  // Add more booking as needed
}

interface GetBookingResponse {
  type: string
  payload: {
    booking: Booking[]
  }
}

interface LocationState {
  data: any
}

interface KeyAccountManager {
  id: number
  User: User
}

interface User {
  first_name: string
  last_name: string
}

interface Channel {
  id: number
  name: string
  url: string
}

export const detailsTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          // backgroundColor:'yellow'
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #999',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    width: '100%',
    margin: '0',
    padding: '20px',
    borderRadius: '10px',
  },
  Rightwrapper: {
    border: '1px solid #999',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    width: '100%',
    margin: '0',
    padding: '20px',
    borderRadius: '10px',
    marginRight: '20px',
    marginTop: '16px',
  },
  gridItem: {
    paddingLeft: '15px',
    paddingBottom: '5px',
    paddingTop: '7px !important',
    border: '1px solid #eee',
    // margin: '10px',
  },
  gridContainer: {
    padding: '5px 0 0 5px !important',
  },
  
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  heading: {
    // fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  editButton: {
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '4px 10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  crosssellButton:{
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '4px 30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  detailsContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  devider: {
    borderColor: '#000',
  },
  
  bold: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
    color: 'rgb(25, 118, 210)',
    cursor: 'pointer',
  },
  sectionHeader: {
    fontSize: '1.3rem !important',
  },
  boxWrap: {
    marginTop: '0 !important',
  },
  propertyName: {
    color: 'rgb(25, 118, 210)',
    cursor: 'pointer',
  },
}))

const formatKey = (key: string): string => {
  return key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
}

const inputFields = {
  amount: '',
  // withoutGst: '',
  // withGst: '',
  // paidAmount: '',
  // securityDeposite: '',
  // collectedAmount: '',
}

const BookingDetails: React.FC = () => {
  const [bookingDetails, setBookingDetails] = useState<Booking | any>()
  const location = useLocation<LocationState>()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const classes = useStyles()
  const [isInvoiceModal, setIsInvoiceModal] = useState<boolean>(false)
  const [isOfferModal, setIsOfferModal] = useState<boolean>(false)
  const [fields, setInputFields] = useState<any>(inputFields)
  const [errors, setErrors] = useState<any>({})
  const [pageTitle, setPageTitle] = useState('Guest Details')
  const config: Config = generateConfig(pageTitle)
  const offers = useSelector((state) => state?.book?.offersGettingState);

  const filteredOffers = offers.filter(offer => offer.name && offer.name.trim() !== '');

  const [selectedOption, setSelectedOption] = useState('')
  const [offersubmitButtonClicked, setOfferSubmitButtonClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [crossSellList, setCrossSellList] = useState<any>([]);
  const [orderedItems, setOrderedItems] = useState<any>('');
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseCrossSell = () => {
    setIsModalOpen(false);
  };
  
  const handleOpen = () => {
    let bookingId = location?.pathname.split('/').filter(Boolean).pop()
    dispatch(openModal(bookingId))
  }
  
  const handleClose = () => dispatch(closeModal())
  
  const handleCloseModal = () => {
    setIsInvoiceModal(false)
  }
  
  const handleOfferCloseModal = () => {
    setOfferSubmitButtonClicked(false)
    setIsOfferModal(false)
    setSelectedOption('');
  }
  
  useEffect(() => {
    const bookingId =
      (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] ||
      ''
    sessionStorage.setItem('BookingId', bookingId)
    if (bookingId) {
      dispatch(getBookingId(bookingId)).then((response: GetBookingResponse) => {
        console.log('response', response)
        if (response.type === 'getBookingId/fulfilled') {
          setBookingDetails(response.payload ? response.payload : '')
          setPageTitle(
            `${response?.payload?.GuestUser?.User?.id} - ${response?.payload?.GuestUser?.User?.name}`
          )
        }
      })
    }
    dispatch(getOffersList())
  }, [])

  useEffect(()=>{

   if(bookingDetails?.cross_sell_order?.order_items?.length){
     dispatch(getCrosssellOrder(bookingDetails?.cross_sell_order?.order_items[0].order_id)).then((response) => {
       if (response.type === 'getCrosssellOrder/fulfilled') {
         setOrderedItems(response.payload)
       }
     })
   } else{
     setOrderedItems('')
   }

  },[bookingDetails])

  useEffect(()=>{
    dispatch(getCrosssellList()).then((response) => {
      console.log('response cross sell', response)
      if (response.type === 'getCrosssellList/fulfilled') {
        setCrossSellList(response.payload)
       
      }
    })
  },[])
  
  const handleGoToEditPage = (name: string) => {
    if (name) {
      navigate(`/bookings/${name}`)
    }
  }

  const handleRemoveOffer = (offerId: number) => {
    if (confirm("Do you really want to remove offer!") == true)
    {
      const bookingId =
          (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] || '';
      const offerData = {
        id: bookingId,
        couponId: offerId,
      };

      dispatch(removeOffer(offerData)).then((response: any) => {
        if (response.payload === true) {
          dispatch(getBookingId(bookingId)).then((response: GetBookingResponse) => {
            if (response.type === 'getBookingId/fulfilled') {
              setBookingDetails(response.payload ? response.payload : '');
            }
          });
        }
      });
    }
  };
  
  const keysToDisplay: { [key: string]: string } = {
    ota: 'OTA/Channel',
    ota_booking_code: 'Booking Code',
    booking_at: 'Date of Booking',
    checkin_at: 'Date of CheckIn',
    checkout_at: 'Date of Checkout',
    room_nights_count: 'Room Nights',
    propertyName: 'Property Name',
  }
  
  const guestDetailsKeysToDisplay: { [key: string]: string } = {
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    revenueManagerName: 'Revenue Manager',
  }
  
  const handleOpenInvoicesModal = () => {
    setIsInvoiceModal(true)
  }
  
  
  const handleOpenOffersModal = () => {
    setIsOfferModal(true)
  }
  
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.persist()
    const {name, value} = e.currentTarget
    setInputFields((prevFields: any) => ({
      ...prevFields,
      [name]: value,
    }))
    setErrors((prevErrors: any) => {
      const {[name]: removedField, ...updatedError} = prevErrors
      return updatedError
    })
  }
  
  const handleDropdownChange  = (event: React.SyntheticEvent<Element, Event>, newValue: any | null, name: string) => {
    const value = newValue ? newValue.id : null;

    console.log('event.target.value, setSelectedOption', value);
    setSelectedOption(value);
  };
  
  const addOfferSubmit = (event) => {
    event.preventDefault();
    
    setOfferSubmitButtonClicked(true);
    
    console.log('offers', offers);
    
    console.log('selectedOption', selectedOption);
    const selectedCoupon = offers.find((offer) => offer.id === selectedOption);
    console.log('selectedCoupon', selectedCoupon);
    const bookingId =
      (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] ||
      ''
    
    if (selectedCoupon) {
      const offerData = {
        id: bookingId,
        couponId: selectedCoupon.Coupons[0].id,
      };
      
      console.log('offerData', offerData);
      
      dispatch(addOffer(offerData)).then((response: any) => {
        if (response.payload === true) {
          dispatch(getBookingId(bookingId)).then(
            (response: GetBookingResponse) => {
              if (response.type === 'getBookingId/fulfilled') {
                setBookingDetails(response.payload ? response.payload : '')
                handleOfferCloseModal()
              }
            }
          )
        }
      })
      setOfferSubmitButtonClicked(false)
      setSelectedOption('');
    }
    
    
  }
  
  
  const submitInvoices = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const bookingId =
      (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] ||
      ''
    let invoiceData = {
      total_amount: fields.amount,
      id: bookingId,
    }
    dispatch(createInvoices(invoiceData)).then((response: any) => {
      if (response.payload === true) {
        dispatch(getBookingId(bookingId)).then(
          (response: GetBookingResponse) => {
            if (response.type === 'getBookingId/fulfilled') {
              setBookingDetails(response.payload ? response.payload : '')
              setIsInvoiceModal(false)
            }
          }
        )
      }
    })
  }

  const fetchBookingDetail=()=>{
    const bookingId =
      (location?.pathname.split('/').filter(Boolean).slice(-2, -1) || [])[0] ||
      ''
    dispatch(getBookingId(bookingId)).then(
      (response: any) => {
        if (response.type === 'getBookingId/fulfilled') {
          setBookingDetails(response.payload ? response.payload : '')
       
        }
      }
    )
  }
  
  const handleRedirectToProperty = (booking: any) => {
    const properId = booking?.property_id
    if (!properId) return ''
    return (
      <>
        <Link
          href={
            process.env.PMS_ADMIN_URL + '/properties/' + properId + '/details'
          }
          target='_blank'
          style={{
            textDecoration: 'underline',
            color: '#1976d2',
            cursor: 'pointer',
          }}
        >
          {'#' + booking?.Property?.id + ' - ' + booking?.propertyName}
        </Link>
        <span>
          {', ' +
            booking?.Property?.Address?.area +
            ', ' +
            booking?.Property?.Address?.city}
        </span>
      </>
    )
  }
  
  const handleGoToEditStrapi = (id: any) => {
    const reviewId = id
    if (!id) return
    
    const url =
      process.env.CMS_ADMIN_URL +
      `/admin/content-manager/collectionType/api::review.review/${reviewId}`
    window.open(url, '_blank')
  }
  
  const handleGoToAddStrapi = () => {
    const url =
      process.env.CMS_ADMIN_URL +
      `/admin/content-manager/collectionType/api::review.review?page=1&pageSize=10&sort=id:ASC`
    window.open(url, '_blank')
  }
  
  const handleGoToUserEdit = (details: any) => {
    const userId = details?.GuestUser?.User?.id
    if (!userId) return
    const url = process.env.PMS_ADMIN_URL + `/users/${userId}`
    window.open(url, '_blank')
  }
  
  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      {isInvoiceModal ? (
        <ModalComponent
          modalContent={
            <Box sx={{ mt: 1 }}>
              <Grid
                container
                rowSpacing={1}
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Enter Amount'}
                    id={'amount'}
                    name='amount'
                    onChange={handleInputChange}
                    value={fields.amount}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.amount} // Update the errorMsg prop
                    error={errors?.errors?.amount ? true : false} // Update the error prop
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Booking Amount (Without GST)'}
                    id={'withoutGst'}
                    name='withoutGst'
                    onChange={handleInputChange}
                    value={fields.withoutGst}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.withoutGst} // Update the errorMsg prop
                    error={errors?.errors?.withoutGst ? true : false} // Update the error prop
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Booking Amount (with GST)'}
                    id={'withGst'}
                    name='withGst'
                    onChange={handleInputChange}
                    value={fields.withGst}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.withGst} // Update the errorMsg prop
                    error={errors?.errors?.withGst ? true : false} // Update the error prop
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Paid Amount'}
                    id={'paidAmount'}
                    name='paidAmount'
                    onChange={handleInputChange}
                    value={fields.paidAmount}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.paidAmount} // Update the errorMsg prop
                    error={errors?.errors?.paidAmount ? true : false} // Update the error prop
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Security Deposite'}
                    id={'securityDeposite'}
                    name='securityDeposite'
                    onChange={handleInputChange}
                    value={fields.securityDeposite}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.securityDeposite} // Update the errorMsg prop
                    error={errors?.errors?.securityDeposite ? true : false} // Update the error prop
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Input
                    margin='normal'
                    label={'Collected Amount'}
                    id={'collectedAmount'}
                    name='collectedAmount'
                    onChange={handleInputChange}
                    value={fields.collectedAmount}
                    autoFocus
                    required
                    type='number'
                    disabled={false}
                    errorMsg={errors?.errors?.collectedAmount} // Update the errorMsg prop
                    error={errors?.errors?.collectedAmount ? true : false} // Update the error prop
                  />
                </Grid> */}
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={submitInvoices}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          }
          open={isInvoiceModal}
          setOpen={setIsInvoiceModal}
          handleClose={handleCloseModal} // Add the handleClose prop
        />
      ) : null}

      {isOfferModal ? (
        <ModalComponent
          modalContent={
            <Box sx={{ mt: 1 }}>
              <Grid
                container
                rowSpacing={1}
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <div style={{ width: '100%', padding: '10px' }}>
                    <Dropdown
                      options={filteredOffers}
                      value={selectedOption}
                      name='selectedOption'
                      label='Select an option'
                      onChange={(event, newValue) =>
                        handleDropdownChange(event, newValue, 'selectedOption')
                      }
                      error={errors?.channel ? true : false}
                      errormsg='Error message'
                    />
                  </div>

                  {offersubmitButtonClicked && !selectedOption && (
                    <Typography color='error' variant='caption'>
                      Please select an option.
                    </Typography>
                  )}
                </Grid>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={addOfferSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          }
          open={isOfferModal}
          setOpen={setIsOfferModal}
          handleClose={handleOfferCloseModal} // Add the handleClose prop
        />
      ) : null}

      <BreadcrumbsComponent />
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                  marginTop: 0,
                }}
                className={`${classes.boxWrap}`}
              >
                <Typography
                  variant='h5'
                  component='h3'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Basic Details
                </Typography>
                {bookingDetails && (
                  <Typography
                    variant='h6'
                    component='h6'
                    className={classes.editButton}
                    onClick={() =>
                      handleGoToEditPage(
                        sessionStorage.getItem('BookingId') ?? ''
                      )
                    }
                  >
                    <span>Edit</span>
                  </Typography>
                )}
              </Box>
              {bookingDetails ? (
                <Grid container spacing={2} className={classes.gridContainer}>
                  {Object.entries(bookingDetails).map(([key, value]) => {
                    if (keysToDisplay.hasOwnProperty(key)) {
                      let formattedValue = value
                      let standardValue = ''
                      if (
                        key === 'booking_at' ||
                        key === 'checkin_at' ||
                        key === 'checkout_at'
                      ) {
                        // formattedValue = moment(value).format('Do MMM')
                        formattedValue = moment(value).format('Do MMM YYYY')
                        standardValue = moment(value).format('DD-MM-YYYY ')
                      }
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={4} className={classes.gridItem}>
                            <span>{`${keysToDisplay[key]}:`}</span>
                          </Grid>
                          <Grid item xs={8} className={classes.gridItem}>
                            {key === 'propertyName' ? (
                              handleRedirectToProperty(bookingDetails)
                            ) : (
                              <span
                                title={standardValue}
                                className={
                                  key === 'propertyName'
                                    ? classes.propertyName
                                    : ''
                                }
                              >
                                {typeof formattedValue === 'object' &&
                                formattedValue !== null &&
                                formattedValue
                                  ? formattedValue.first_name
                                  : formattedValue || 'N/A'}
                              </span>
                            )}
                          </Grid>
                        </React.Fragment>
                      )
                    }
                    return null
                  })}
                </Grid>
              ) : (
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    fontFamily: 'Roboto Helvetica Arial sans-serif',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    marginBottom: '0.35em',
                    fontWeight: 'bold',
                  }}
                >
                  Not Available
                </Typography>
              )}
            </Paper>
          </Container>

          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant='h5'
                  component='h1'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Guest Details
                </Typography>
                {bookingDetails && (
                  <Typography
                    variant='h6'
                    component='h6'
                    className={classes.editButton}
                    onClick={() => handleGoToUserEdit(bookingDetails)}
                  >
                    <span>Edit</span>
                  </Typography>
                )}
              </Box>
              {bookingDetails?.GuestUser?.User ? (
                <Grid container spacing={2} className={classes.gridContainer}>
                  {Object.entries(bookingDetails.GuestUser.User).map(
                    ([key, value]) => {
                      if (guestDetailsKeysToDisplay.hasOwnProperty(key)) {
                        return (
                          <>
                            <Grid item xs={4} className={`${classes.gridItem}`}>
                              {`${guestDetailsKeysToDisplay[key]}:`}
                            </Grid>
                            <Grid item xs={8} className={classes.gridItem}>
                              {typeof value === 'object' &&
                              value !== null &&
                              value.User
                                ? value.User.first_name
                                : value || 'N/A'}
                            </Grid>
                          </>
                        )
                      }
                      return null
                    }
                  )}
                </Grid>
              ) : (
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    fontFamily: 'Roboto Helvetica Arial sans-serif',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    marginBottom: '0.35em',
                    fontWeight: 'bold',
                  }}
                >
                  Not Available
                </Typography>
              )}
            </Paper>
          </Container>

          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant='h5'
                  component='h1'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Invoices
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  className={classes.editButton}
                >
                  <span onClick={handleOpenInvoicesModal}>Add Invoices</span>
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                className={classes.gridContainer}
                style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
              >
                <table
                  style={{
                    fontFamily: 'arial sans-serif',
                    borderCollapse: 'collapse',
                    width: '100%',
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      ID
                    </th>
                    {/* <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Booking Amount (Without GST)
                    </th> */}
                    {/* <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      GST
                    </th> */}
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Total Amount (GBV)
                    </th>
                    {/* <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Paid Amount
                    </th> */}
                    {/* <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Security Deposite
                    </th> */}
                    {/* <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Amount to be Collected
                    </th> */}
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Created
                    </th>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Last Updated
                    </th>
                  </tr>
                  {bookingDetails?.Invoices?.map((item: any, index: any) => {
                    return (
                      <tr>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {item?.id}
                        </td>
                        {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {'N/A'}
                        </td> */}
                        {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {'N/A'}
                        </td> */}
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{item?.total_amount}
                        </td>
                        {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                        {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                        {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {moment(item?.created_at).format(
                            'DD-MM-YYYY hh:mm A'
                          )}
                        </td>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {moment(item?.updated_at).format(
                            'DD-MM-YYYY hh:mm A'
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </Grid>
            </Paper>
          </Container>
          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant='h5'
                  component='h1'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Offers
                </Typography>

                <Typography
                  variant='h6'
                  component='h6'
                  className={classes.editButton}
                >
                  <span onClick={handleOpenOffersModal}>Add Offer</span>
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                className={classes.gridContainer}
                style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
              >
                <table
                  style={{
                    fontFamily: 'arial sans-serif',
                    borderCollapse: 'collapse',
                    width: '100%',
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Added Offers
                    </th>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                  {bookingDetails?.CouponRedemption?.map(
                    (item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {item?.Coupons?.id}
                          </td>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {item?.Coupons?.Offer.name}
                          </td>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            <button
                              onClick={() => handleRemoveOffer(item?.id)}
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                padding: '5px 10px',
                                cursor: 'pointer',
                              }}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      )
                    }
                  )}
                </table>
              </Grid>
            </Paper>
          </Container>
          {
            bookingDetails?.Property?.is_owner_x_sell &&  <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant='h5'
                  component='h1'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Cross Sell
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  className={classes.crosssellButton}
                  onClick={handleOpenModal}
                >
                  <span>
                    {bookingDetails?.cross_sell_order?.id ? 'Edit' : 'Add'}
                  </span>
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                className={classes.gridContainer}
                style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
              >
                <table
                  style={{
                    fontFamily: 'arial sans-serif',
                    borderCollapse: 'collapse',
                    width: '100%',
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      S No.
                    </th>

                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Added Services
                    </th>

                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Total Price
                    </th>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Created
                    </th>
                    <th
                      style={{
                        border: '1px solid #dddddd',
                        textAlign: 'left',
                        padding: '8px',
                      }}
                    >
                      Last Updated
                    </th>
                  </tr>
                  {bookingDetails?.cross_sell_order?.order_items?.map(
                    (item: any, index: any) => {
                      return (
                        <tr>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {index + 1 < 10 ? `0${index + 1}` : index + 1}
                          </td>
                          {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {'N/A'}
                        </td> */}
                          {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {'N/A'}
                        </td> */}
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {item?.name}
                            <span style={{ margin: '0 4px' }}>×</span>{' '}
                            {/* Multiplication symbol */}
                            {item?.quantity}
                          </td>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            ₹{item?.total_amount}
                          </td>
                          {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                          {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                          {/* <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          ₹{'N/A'}
                        </td> */}
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {moment(item?.created_at).format(
                              'DD-MM-YYYY hh:mm A'
                            )}
                          </td>
                          <td
                            style={{
                              border: '1px solid #dddddd',
                              textAlign: 'left',
                              padding: '8px',
                            }}
                          >
                            {moment(item?.updated_at).format(
                              'DD-MM-YYYY hh:mm A'
                            )}
                          </td>
                        </tr>
                      )
                    }
                  )}
                </table>
              </Grid>
              <Grid
                container
                justifyContent='flex-end'
                style={{ marginTop: '20px' }}
              >
                <Grid item xs={12} md={10}>
                  <Paper
                    elevation={0}
                    style={{
                      border: '1px solid #dddddd',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                  >
                    <Grid container
                    flexWrap='nowrap'
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          borderRight: '1px solid #dddddd',
                        }}
                      >
                        <Typography
                          style={{
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                              gap:'4px',
                              overflow: 'auto',
                              maxWidth: '100%',
                          }}
                        >
                          <span>Sub Total = </span>
                          <span style={{ color: '#000' }}>
                            ₹
                            {bookingDetails?.cross_sell_order?.total_amount ||
                              0}
                           
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          borderRight: '1px solid #dddddd',
                        }}
                      >
                        <Typography
                          style={{
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            gap:'4px',
                            overflow: 'auto',
                            maxWidth: '100%',
                          }}
                        >
                          <span>Amount Paid = </span>
                          <span style={{ color: '#4CAF50' }}>
                            ₹
                            {bookingDetails?.cross_sell_order?.total_amount ||
                              0}
                            
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          style={{
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            overflow: 'auto',
                            maxWidth: '100%',
                            gap:'4px'
                          }}
                        >
                          <span>Amount Pending = </span>
                          <span style={{ color: '#F44336' }}>₹0</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          }
         
          <CrossSellModal open={isModalOpen} onClose={handleCloseCrossSell} crossellList={crossSellList} fetchBookingDetail={fetchBookingDetail} orderedCrosssell = {orderedItems} orderId = {bookingDetails?.cross_sell_order?.id} />
          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 10, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Paper className={classes.wrapper}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant='h5'
                  component='h1'
                  gutterBottom
                  className={`${classes.sectionHeader}`}
                >
                  Reviews
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  className={classes.editButton}
                  onClick={() => handleGoToAddStrapi()}
                >
                  <span>Add</span>
                </Typography>
              </Box>
              {Array.isArray(bookingDetails?.Reviews) &&
              bookingDetails?.Reviews.length > 0 ? (
                <Grid container spacing={2} className={classes.gridContainer}>
                  <table
                    style={{
                      fontFamily: 'arial sans-serif',
                      borderCollapse: 'collapse',
                      width: '100%',
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          border: '1px solid #dddddd',
                          textAlign: 'left',
                          padding: '8px',
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          border: '1px solid #dddddd',
                          textAlign: 'left',
                          padding: '8px',
                        }}
                      >
                        Title
                      </th>
                      <th
                        style={{
                          border: '1px solid #dddddd',
                          textAlign: 'left',
                          padding: '8px',
                        }}
                      >
                        Rating
                      </th>
                      <th
                        style={{
                          border: '1px solid #dddddd',
                          textAlign: 'left',
                          padding: '8px',
                        }}
                      >
                        Published
                      </th>
                      <th
                        style={{
                          border: '1px solid #dddddd',
                          textAlign: 'left',
                          padding: '8px',
                        }}
                      >
                        Edit
                      </th>
                    </tr>
                    {bookingDetails?.Reviews.map((review) => (
                      <tr key={review.id}>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {review.id}
                        </td>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {review.title}
                        </td>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {review.rating}
                        </td>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          {moment(review.published_at).format(
                            'DD-MM-YYYY hh:mm A'
                          )}
                        </td>
                        <td
                          style={{
                            border: '1px solid #dddddd',
                            textAlign: 'left',
                            padding: '8px',
                          }}
                        >
                          <EditIcon
                            fontSize='small'
                            onClick={() => handleGoToEditStrapi(review.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>
                </Grid>
              ) : (
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    fontFamily: 'Roboto Helvetica Arial sans-serif',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    marginBottom: '0.35em',
                    fontWeight: 'bold',
                  }}
                >
                  Not Available
                </Typography>
              )}

              {/* {bookingDetails?.Review?.rating ? (
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item xs={4} className={`${classes.gridItem}`}>
                    Rating:
                  </Grid>
                  <Grid item xs={8} className={`${classes.gridItem}`}>
                    {bookingDetails?.Review?.rating}
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    fontFamily: 'Roboto Helvetica Arial sans-serif',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    marginBottom: '0.35em',
                    fontWeight: 'bold',
                  }}
                >
                  Not Available
                </Typography>
              )} */}
            </Paper>
          </Container>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.Rightwrapper}>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                marginTop: 0,
              }}
            >
              <Typography
                variant='h5'
                component='h3'
                gutterBottom
                className={`${classes.sectionHeader}`}
              >
                Stage
              </Typography>
            </Box> */}
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item xs={4}>
                Stage:
              </Grid>
              <Grid item xs={8}>
                {bookingDetails?.LifeCycleStage?.name}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default BookingDetails
